<template>
  <div>
    <div style="display: flex">
      <kafkaSelect @kafkaChange="kafkaChange"></kafkaSelect>

      <!-- <el-input placeholder="搜索group" v-model="keyword" style="width: 250px;margin-left: 5px" clearable
                @keyup.enter.native="searchGroup">
        <el-button slot="append" icon="el-icon-search" @click="searchGroup"></el-button>
      </el-input> -->
      

      <!-- <el-button
        type="primary"
        @click="dialogFormVisible = true"
        size="small"
        style="margin-bottom: 5px"
        >创建topic
      </el-button> -->
    </div>
    <el-table :data="tableData" stripe border>
      <el-table-column prop="name" label="管道组名称"></el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            round
            type="info"
            @click="getGroupDetail(scope.row.name)"
          >
            查看消费组详情
          </el-button>
          <!-- <el-popconfirm title="确定删除吗？" @onConfirm="deleteConfirm(scope.row.name)" v-if="!scope.row.internal">
            <el-button size="mini" circle type="danger" slot="reference" style="margin-left: 5px"
                       :disabled="!auth.remove">
              <i class="el-icon-delete"></i>
            </el-button>
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="group消费偏移量详情" :visible.sync="dialogTableVisible">
      <group-table :data="detail"></group-table>
    </el-dialog>
  </div>
</template>

<script>
import kafkaSelect from "../kafkaSelect.vue";
import GroupTable from "../common/GroupTable.vue";
import axios from "axios";

export default {
  name: "group",
  data() {
    return {
      sourceId: null,
      tableData: [],
      detail: [],
      dialogTableVisible: false,
      auth: { add: false, update: false, remove: false },
      keyword: null,
    };
  },
  methods: {
    searchGroup() {
      let params = new URLSearchParams();
      params.append("sourceId", this.sourceId);
      params.append("keyword", this.keyword);
      axios
        .post("/kafka/group/search", params)
        .then((response) => {
          if (response.data.success) this.tableData = response.data.data;
          else this.$message.error(response.data.message);
        })
        .catch((error) => {
          this.$message.error("查询所有group失败");
        });
    },
    getGroups() {
      let params = new URLSearchParams();
      params.append("sourceId", this.sourceId);
      axios
        .post("/kafka/group/all", params)
        .then((response) => {
          if (response.data.success) {
            this.tableData = response.data.data;
            console.log(this.tableData);
          } else this.$message.error(response.data.message);
        })
        .catch((error) => {
          this.$message.error("查询所有group失败");
        });
    },
    kafkaChange(sourceId) {
      this.sourceId = sourceId;
      // this.auth = this.$store.getters.getKafkaAuth(sourceId)
      this.getGroups();
    },
    getGroupDetail(group) {
      let params = new URLSearchParams();
      params.append("sourceId", this.sourceId);
      params.append("group", group);
      axios
        .post("/kafka/group/detail", params)
        .then((response) => {
          if (response.data.success) {
            this.detail = response.data.data;
            this.dialogTableVisible = true;
          }
        })
        .catch((error) => {
          this.$message.error("查询group详情失败");
        });
    },
    // deleteConfirm(group) {
    //   this.axios.post("/kafka/group/delete", {"sourceId": this.sourceId, "group": group}).then((response) => {
    //     this.$message.success("删除group成功")
    //     this.getGroups()
    //   }).catch((error) => {
    //     this.$message.error("删除group失败")
    //   })
    // }
  },
  components: {
    kafkaSelect,
    GroupTable,
  },
};
</script>

<style scoped>
i {
  font-size: 14px;
}
</style>
